<template lang="pug">
.selector-container(data-cy='module-selector')
    .status-cta(v-if='isDesktopDevice')
        .status
            h6.helper-text {{ $t('courses.sideshow.currentlyStudying') }}
            h6 {{ studyingStatus }}
        .cta(
            :class='{ buyCourse: shouldBuyCourse }',
            @click='actionCta',
            v-if='showNextCTA(ctaStatus) && !(isNewModule || isNewLesson)'
        )
            LoadingSpinner(v-if='loadingShopifyCheckout')
            template(v-else)
                h5 {{ ctaStatus }}
                SVGRenderer(
                    :has-hover='false',
                    :icon='arrowRight',
                    :stroke-color='shouldBuyCourse ? "var(--primary-foreground-color)" : "var(--primary-text-color)"'
                )
    CourseExamDate(
        :exam-date-details='course.examDateDetails || {}',
        v-if='showCourseExamDate'
    )
    .extension-banner(
        @click='openBrowserExtensionModal',
        v-if='course && course.showAdvert && canAdvertiseExtension && isLargeDesktop'
    )
        img.close(
            :src='closeIcon',
            @click.stop='closeExtensionAdvert'
        )
        .text
            h5 {{ $t('browserExtension.title') }}
            h5.bold {{ $t('browserExtension.learnOnTheSide') }}
        .extensions
            .chrome
                img(
                    :src='chrome',
                    alt='chrome'
                )
                h6 Google Chrome
            .edge
                img(
                    :src='edge',
                    alt='chrome'
                )
                h6 Microsoft Edge
    .selector-wrapper
        EditorButtons(
            :hide-save-discard-buttons='true',
            path='module-selector',
            v-if='inEditorMode && isLargeDesktop'
        )
        InTrialCourseTimer(
            @trial-period-expired='trialPeriodExpired = $event',
            v-if='showCourseTrialTimer'
        )
        .selector-content-container(:class='{ editing: inEditorMode && isLargeDesktop }')
            .header-section
                h3.header-info {{ course.title }}
                CourseExamDate(
                    ::exam-date-details='course.examDateDetails || {}',
                    v-if='isMobileDevice && course.hasRealExamDates && course.examDateDetails.hasRealExamDates'
                )
                Dropdown(
                    :add-item-text='$t("courses.createCourseGroup")',
                    :hide-toggle-dropdown='inEditorMode',
                    :items='courseGroupDropdownItems',
                    :open-dropdown-by-default='inEditorMode',
                    :show-add-item='inEditorMode',
                    :show-edit-item-icon='inEditorMode',
                    @add-item='showAddCourseGroupModal',
                    @delete='deleteCourseGroup($event)',
                    @edit='editCourseGroup($event)',
                    stroke-color-for-expand-icon='var(--secondary-foreground-color)',
                    v-if='courseGroupDropdownItems && courseGroupDropdownItems.length',
                    v-model='selectedCourseGroup'
                )
                KetchUpButton.editor.add-to-course-group(
                    @click.native='showAddCourseGroupModal',
                    v-else-if='inEditorMode'
                )
                    SVGRenderer(:icon='plusIcon')
                    h5 {{ $t('courses.addToCourseGroup') }}
                .meta-info
                    .content-count
                        SVGRenderer(
                            :has-hover='false',
                            :icon='moduleIcon',
                            fill-color='var(--primary-color)',
                            height='20',
                            stroke-color='var(--primary-color)',
                            width='20'
                        )
                        .count
                            h5 {{ $t('courses.sideshow.contentCount', { moduleCount: moduleCount, lessonsCount: moduleContentCount.lessonsCount }) }}
                    .duration
                        SVGRenderer(
                            :has-hover='false',
                            :icon='durationIcon',
                            height='18',
                            stroke-color='var(--primary-color)',
                            width='18'
                        )
                        h5 {{ formatDurationToHHMM(moduleContentCount.moduleDuration) }}h
                CourseSearch
            .modules-lessons
                template(v-for='(module, index) in filteredCourseModules')
                    .module(
                        :class='{ active: currentModule.id === module.id, open: isModuleOpen(module.id), "drop-target": isModuleDropTarget(module.id), fake: module.id === "123" }',
                        :disabled='module.disabled && !inEditorMode',
                        :draggable='inEditorMode && !isSpecificModuleNew(module.id)',
                        :key='module.id',
                        :title='module.name',
                        @dragend='onModuleDragEnd',
                        @dragenter='onModuleDragEnter(module)',
                        @dragover.prevent,
                        @dragstart='onModuleDragStart(index)',
                        @drop.stop='onModuleDrop'
                    )
                        .module-info
                            Badge.free(
                                :badge-value='$t("courses.free")',
                                v-if='module.isFree && !isPurchasedCourse'
                            )
                            .module-wrapper(v-if='module.id !== "123"')
                                .overall-info-container
                                    .overall-info(@click='routeToModule(module)')
                                        .left-attr
                                            .ordering-number(:class='{ active: currentModule.id === module.id }')
                                                h6.notranslate {{ convertToRoman(inEditorMode ? index : index + 1) }}
                                        h5.name(:class='{ active: currentModule.id === module.id }') {{ module.name }}
                                .meta-info(
                                    @click='toggleModule(module.id)',
                                    @mouseleave='hoveringIndex = -1',
                                    @mouseover='hoveringIndex = index',
                                    v-if='module.lessons.length'
                                )
                                    SVGRenderer(
                                        :icon='quizIcon',
                                        :stroke-color='moduleQuizStrokeColor(module)',
                                        v-if='moduleHasQuiz(module)'
                                    )
                                    h5 {{ completedProgressCount(module) }}
                                    SVGRenderer.expand-button(
                                        :class='{ open: isModuleOpen(module.id) }',
                                        :icon='arrowDown',
                                        :toggle-hover='hoveringIndex === index',
                                        stroke-color='#999',
                                        stroke-hover-color='var(--primary-color)'
                                    )
                                Drag(v-if='inEditorMode && !isSpecificModuleNew(module.id)')
                        transition(name='menu-slide')
                            .lessons(v-show='isModuleOpen(module.id)')
                                .lesson(
                                    :class='{ active: lessonId === lesson.id, "drop-target": isLessonDropTarget(lesson.id), "space-out": isDraggingLesson, locked: isLockedModule(module.isFree) }',
                                    :draggable='inEditorMode && lesson.type !== "Fake" && !isSpecificLessonNew(lesson.id)',
                                    :key='lesson.id',
                                    @dragend='onLessonDragEnd(module)',
                                    @dragenter='onLessonDragEnter(module, lesson.id)',
                                    @dragover.prevent,
                                    @dragstart='onLessonDragStart(module, lessonIndex)',
                                    @drop='onLessonDrop',
                                    v-for='(lesson, lessonIndex) in module.lessons'
                                )
                                    .wrapper
                                        .overall-info(
                                            @click='routeToLesson(lesson, module.id, module.isFree)',
                                            data-cy='lesson-item'
                                        )
                                            .left-attr(v-if='lesson.type !== "Fake"')
                                                Drag(v-if='inEditorMode && !isSpecificLessonNew(lesson.id)')
                                                SVGRenderer(
                                                    :has-hover='false',
                                                    :icon='lockIcon',
                                                    height='15',
                                                    stroke-color='var(--primary-color)',
                                                    v-else-if='isLockedModule(module.isFree)',
                                                    width='15'
                                                )
                                                img.active-lesson-icon(
                                                    :src='activeLessonIcon',
                                                    v-else-if='currentLesson && currentLesson.id === lesson.id'
                                                )
                                                SVGRenderer(
                                                    :has-hover='false',
                                                    :icon='quizIcon',
                                                    :stroke-color='lessonQuizStrokeColor(lesson)',
                                                    v-else-if='lesson.type === "Quiz"'
                                                )
                                                SVGRenderer(
                                                    :has-hover='false',
                                                    :icon='lessonCompletedIcon',
                                                    stroke-color='var(--primary-color)',
                                                    v-else-if='lesson.completed && lesson.type !== "Quiz"'
                                                )
                                                img.open(
                                                    :src='open',
                                                    v-else
                                                )
                                            h5.lesson-title {{ lesson.title }}
                                            .pencil-icon
                                                SVGRenderer(
                                                    :has-hover='false',
                                                    :icon='pencilIcon',
                                                    fill-color='var(--primary-color)',
                                                    height='15',
                                                    v-if='lesson.hasNote',
                                                    width='15'
                                                )
                                        .meta-info(v-if='lesson.type !== "Fake"')
                                            h5 {{ lesson.duration }}min
                                .add-new-lesson(
                                    @click='addNewLesson(module.id)',
                                    v-if='inEditorMode && !isSpecificModuleNew(module.id)'
                                )
                                    .left-attr
                                        SVGRenderer(
                                            :has-hover='false',
                                            :icon='plusIcon',
                                            fill-color='var(--editor-primary-color)',
                                            height='15',
                                            width='15'
                                        )
                                    h5.lesson-title {{ $t('editor.newLesson') }}
                                .guided-quiz-container(
                                    :class='{ "in-editor-mode": inEditorMode, locked: isLockedModule(module.isFree) }'
                                )
                                    router-link.guided-quiz-title(
                                        :class='{ active: currentModule && currentModule.id === module.id && $route.name === "GuidedQuiz" }',
                                        :to='`/courses/${course.id}/module/${module.id}/guided-quiz`',
                                        @click.native='$emit("show-content")',
                                        data-cy='guided-quiz',
                                        v-if='module.guidedQuiz && module.enableGuidedQuiz'
                                    )
                                        SVGRenderer(
                                            :has-hover='false',
                                            :icon='quizIcon',
                                            stroke-color='var(--primary-text-color)'
                                        )
                                        .details
                                            h5 {{ $t('courses.guidedQuiz', { numberOfQuestions: module.guidedQuiz.numberOfQuestions }) }}
                                            .meta-info
                                                h5 {{ formattedGuidedQuizDuration(module) }}min
                                    .blurred-out-non-editable-section(v-if='inEditorMode')
                    .module.placeholder(:key='index')
                .talk-to-expert(
                    @click='openSpeakToExpertModal',
                    v-if='showTalkToExpert && false'
                )
                    .expert-wrapper
                        img.expert(:src='michaelAvatar')
                        .helper-texts
                            h6 {{ $t('courses.talkToExpert.notSureIfTrainingIsForYou') }}
                            h5 {{ $t('courses.talkToExpert.speakToMichael') }}
                        SVGRenderer(
                            :fill-color='"var(--primary-foreground-color)"',
                            :has-hover='false',
                            :icon='phoneIcon',
                            height='30px',
                            width='23px'
                        )
                router-link.download-materials.module(
                    :to='{ name: "DownloadMaterials" }',
                    @click.native='$emit("show-content")',
                    v-if='totalDownloadMaterials'
                )
                    .icon-copy
                        SVGRenderer(
                            :fill-color='isDarkMode ? "var(--primary-foreground-color)" : "var(--primary-text-color)"',
                            :has-hover='false',
                            :icon='bigDownloadIcon',
                            height='20',
                            width='20'
                        )
                        h5 {{ $t('courses.downloadMaterials') }}
                    .total-materials {{ totalDownloadMaterials }}
            .add-module(
                @click='addNewModule',
                v-if='inEditorMode'
            )
                .left-attr
                    SVGRenderer(
                        :has-hover='false',
                        :icon='plusIcon',
                        fill-color='var(--editor-primary-color)',
                        height='15',
                        width='15'
                    )
                h5 {{ $t('editor.addModule') }}
            .webinar-image(v-if='showWebinar && !inEditorMode && isPurchasedCourse')
                img(
                    :src='course.webinar.teaserImageUrl',
                    @click='openWebinarModal'
                )
    .start-exam-cta(v-if='isDesktopDevice && course.exam && (isPurchasedCourse || isTrialCourse)')
        StartCourseExamCTA(:course='course')
        .blurred-out-non-editable-section(v-if='inEditorMode')
    .passed-quiz-status(v-if='hasCertificateUrl')
        .passed-quiz
            h5 {{ $t('courses.passedTestsToUnlockCertificate', { passed: quizMetaCount.passedQuizCount, totalQuizzes: quizMetaCount.totalQuizCount }) }}
        .cert-cta(:class='{ "passed-all-quizzes": passedAllQuizzes }')
            img(:src='courseCertIconWhite')
            h5 {{ $t('courses.certificateReceived') }}
        .blurred-out-non-editable-section(v-if='inEditorMode')
    GoogleReviewWidget(
        :course-id='course.id',
        v-if='onLessonPage && isDesktopDevice && !inEditorMode'
    )
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import Badge from '@/components/common/Badge.vue'
  import useIcons from '@/composables/useIcons'
  import useDuration from '@/composables/useDuration'
  import useCommonMixin from '@/composables/useCommonMixin'
  import { CourseModule } from '@/store/modules/course'
  import useCourse from '@/composables/useCourse'
  import useSegment from '@/composables/useSegment'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import Dropdown from '@/components/common/Dropdown.vue'
  import Drag from '@/components/common/Drag.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import CookieHelper from '@/helpers/CookieHelper'
  import useEditor from '@/composables/useEditor'
  import { EditorModule } from '@/store/modules/editor'
  import CourseApi from '@/services/api/CourseApi'
  import { v4 as uuidv4 } from 'uuid'
  import EditorButtons from '@/components/editor/EditorButtons.vue'
  import CourseSearch from '@/components/course/CourseSearch.vue'
  import CourseExamDate from '@/components/course/CourseExamDate.vue'
  import GoogleReviewWidget from '@/components/GoogleReviewWidget.vue'
  import InTrialCourseTimer from '@/components/course/InTrialCourseTimer.vue'
  import { useRoute, useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { DropdownItem } from '@/services/interfaces/Common'
  import type {
    CourseModule as CourseModuleState,
    Lesson,
    CourseMap,
    EditorStatePayload,
  } from '@/services/interfaces/Course'
  import type { Nullable } from '@/services/interfaces/Content'
  import useShopifyClient from '@/composables/useShopifyClient'
  import StartCourseExamCTA from '@/components/course/StartCourseExamCTA.vue'

  const emit = defineEmits([
    'next-lesson-or-module',
    'start-module',
    'show-content',
    'trial-period-expired',
    'current-course-group-id',
  ])

  const { translateString } = useI18n()
  const route = useRoute()
  const router = useRouter()
  const {
    moduleIcon,
    durationIcon,
    arrowRight,
    arrowDown,
    open,
    lessonCompletedIcon,
    activeLessonIcon,
    quizIcon,
    courseCertIconWhite,
    bigDownloadIcon,
    lockIcon,
    michaelAvatar,
    phoneIcon,
    pencilIcon,
    chrome,
    edge,
    closeIcon,
    plusIcon,
  } = useIcons()
  const { formatDurationToHHMM } = useDuration()
  const { convertToRoman, isDarkMode, isSachkundeCompany } = useCommonMixin()
  const { isDesktopDevice, isLargeDesktop, isMobileDevice } = useBreakpoint()
  const { createShopifyCheckout } = useShopifyClient()
  const moduleOpenStatus = reactive({ moduleId: '', open: false })
  const {
    course,
    currentCourseId,
    moduleId,
    lessonId,
    currentModule,
    currentLesson,
    courseModules,
    studyingStatus,
    quizMetaCount,
    isPurchasedCourse,
    isTrialCourse,
    guidedQuizDuration,
    nextEnabledModule,
    nextLesson,
    nextLessonIndex,
    fetchingEditorDependencies,
    showNextCTA,
    nextSiblingModule,
    showWebinar,
    openWebinarModal,
    nextCourseModule,
    onLessonPage,
  } = useCourse()
  const { coursePurchaseIntended } = useSegment()
  const { inEditorMode, isNewLesson, isNewModule, currentEditorModule } = useEditor()
  const dropModuleTarget = ref(null as Nullable<CourseModuleState>)
  const dragModuleSource = ref(null as Nullable<CourseModuleState>)
  const dropLessonTarget = ref('')
  const dragLessonSourceIndex = ref(-1)
  const dragModuleSourceIndex = ref(-1)
  const isDraggingQuestion = ref(false)
  const trialPeriodExpired = ref(false)
  const loadingShopifyCheckout = ref(false)

  const nextModuleOrCourseDetails = (nextModule: CourseModuleState | null) => {
    if (nextModule) {
      return translateString('courses.sideshow.nextModule')
    }
    return translateString('courses.toCourseDetails')
  }

  const ctaStatus = computed(() => {
    const currentModuleLessons = currentModule.value?.lessons
    const currentLessonIndex = currentModuleLessons?.findIndex((lesson) => {
      return lesson.id === currentLesson.value?.id
    })
    const nextModule = nextCourseModule(currentCourseGroupId.value)

    if (isPurchasedCourse.value) {
      const siblingIsFree = nextSiblingModule.value(currentCourseId.value, currentCourseGroupId.value)?.isFree
      if (currentLesson.value?.id && currentModuleLessons) {
        if (currentLessonIndex === currentModuleLessons!.length - 1) {
          if (currentModule.value.guidedQuiz) {
            return translateString('courses.sideshow.toGuidedQuiz')
          }
          if (isTrialCourse.value && !siblingIsFree) {
            return translateString('courses.buyCourse')
          }
          return nextModuleOrCourseDetails(nextModule)
        }
        return translateString('courses.sideshow.nextLesson')
      } else if (route.name === 'GuidedQuiz') {
        if (isTrialCourse.value && !siblingIsFree) {
          return translateString('courses.buyCourse')
        }
        return nextModuleOrCourseDetails(nextModule)
      } else if (!currentModuleLessons.length) {
        return nextModuleOrCourseDetails(nextModule)
      }
      return translateString('courses.sideshow.startModule')
    }
    return translateString('courses.buyCourse')
  })

  const shouldBuyCourse = computed(() => !course.value?.purchased)

  const isLockedModule = computed(() => (isFree: boolean) => {
    return !isPurchasedCourse.value && !inEditorMode.value && !isFree
  })

  const currentCourseGroupId = computed(() => CourseModule.currentCourseGroupId)

  const selectedCourseGroup = ref('')
  const courseGroupDropdownItems = computed((): DropdownItem[] => {
    return (
      course.value?.courseGroups?.map((group) => ({
        selectable:
          !!CourseModule.courseModules(currentCourseId.value, group.id).filter((mod) => !mod.disabled).length ||
          inEditorMode.value,
        type: group.id,
        title: group.name,
      })) || []
    )
  })

  const filteredCourseModules = computed(() => {
    if (EditorModule.editMode && !fetchingEditorDependencies.value) {
      if (!courseGroupDropdownItems.value.length) {
        const modules = EditorModule.coursesModuleMap[currentCourseId.value] || []

        if (modules.some((m) => m.id === '123')) return modules
        modules.unshift(fakeModulePayload)
        return modules
      }
      const modules = EditorModule.courseModules(currentCourseId.value, selectedCourseGroup.value) || []
      if (modules.some((m) => m.id === '123')) return modules
      modules.unshift(fakeModulePayload)
      return modules
    }
    if (!courseGroupDropdownItems.value) return courseModules.value
    return CourseModule.courseModules(currentCourseId.value, selectedCourseGroup.value)
  })

  const moduleCount = computed(() => {
    if (filteredCourseModules.value?.some((m) => m.id === '123') && !isNewModule.value) {
      return filteredCourseModules.value?.filter((m) => m.id !== '123').length
    }
    return filteredCourseModules.value?.length
  })

  const hasSomeFreeModules = computed(() => filteredCourseModules.value?.some((m) => m.isFree))

  const showTalkToExpert = computed(
    () => hasSomeFreeModules.value && course.value?.talkToExpert && !isPurchasedCourse.value,
  )

  const isSpecificModuleNew = computed(() => (moduleId: string) => (moduleId ? moduleId.startsWith('new-') : false))

  const isSpecificLessonNew = computed(() => (lessonId: string) => (lessonId ? lessonId.startsWith('new-') : false))

  const actionCta = async () => {
    if (isPurchasedCourse.value) {
      if (
        route.name === 'GuidedQuiz' ||
        (currentModule.value.id && currentLesson.value?.id) ||
        currentModule.value.lessons.length === 0
      ) {
        emit('next-lesson-or-module')
        return
      }
      emit('start-module')
      return
    }
    loadingShopifyCheckout.value = true
    await coursePurchaseIntended(currentCourseId.value, course.value!.title, 'selector_cta')
    await createShopifyCheckout()
    setTimeout(() => {
      loadingShopifyCheckout.value = false
    }, 2500)
  }

  const toggleModule = (moduleId: string) => {
    if (isModuleOpen.value(moduleId)) {
      moduleOpenStatus.open = false
      return
    }
    moduleOpenStatus.moduleId = moduleId
    moduleOpenStatus.open = true
  }

  const isModuleOpen = computed(() => {
    return (moduleId: string) => {
      return (
        moduleOpenStatus.moduleId === moduleId &&
        moduleOpenStatus.open &&
        ((inEditorMode.value && !currentEditorModule.value?.disabled) || !currentModule.value.disabled)
      )
    }
  })

  const moduleHasQuiz = computed(() => {
    return (module: CourseModuleState) => {
      return module.lessons.some((lesson) => {
        return lesson.type === 'Quiz'
      })
    }
  })

  const passedModuleQuizzes = computed(() => {
    return (module: CourseModuleState) => {
      const lessonQuizzes = module.lessons.filter((lesson) => {
        return lesson.type === 'Quiz'
      })
      return lessonQuizzes.every((lesson) => {
        return lesson.quizMeta?.passed
      })
    }
  })

  const passedLesson = computed(() => {
    return (lesson: Lesson) => {
      if (!lesson.quizMeta) return false
      return lesson.quizMeta?.passed
    }
  })

  const lessonQuizStrokeColor = computed(() => {
    return (lesson: Lesson) => {
      return passedLesson.value(lesson) ? 'var(--primary-color)' : 'var(--primary-text-color)'
    }
  })

  const moduleQuizStrokeColor = computed(() => {
    return (module: CourseModuleState) => {
      return passedModuleQuizzes.value(module) ? 'var(--primary-color)' : 'var(--primary-text-color)'
    }
  })

  const passedAllQuizzes = computed(() => {
    return quizMetaCount.value.passedQuizCount === quizMetaCount.value.totalQuizCount
  })

  const inCourseModulePage = computed(() => {
    return route.name === 'CourseModule'
  })

  const routeToModule = (module: CourseModuleState) => {
    if (moduleId.value !== module.id || !inCourseModulePage.value) {
      router
        .push({ name: 'CourseModule', params: { courseId: currentCourseId.value, moduleId: module.id } })
        .catch(() => {
          return
        })
      if (moduleId.value !== module.id) {
        toggleModule(module.id)
      }
    }
    if (isMobileDevice.value) emit('show-content')
  }

  const routeToLesson = async (lesson: Lesson, moduleId: string, moduleIsFree: boolean) => {
    if (!moduleIsFree && !isPurchasedCourse.value && !inEditorMode.value) {
      await createShopifyCheckout()
      return
    }
    if (lessonId.value !== lesson.id) {
      if (lesson.quizMeta?.state === 'completed') {
        router
          .push({
            name: 'LessonQuizResults',
            params: { moduleId: moduleId, lessonId: lesson.id },
            query: { completionId: lesson.quizMeta?.completionId },
          })
          .catch(() => {
            return
          })
      } else {
        CourseModule.setCurrentLessonTitle(lesson.title)
        if (isSpecificLessonNew.value(lesson.id)) {
          CourseModule.resetCurrentLesson()
          CourseModule.setCurrentLesson(lesson)
        }
        router.push({ name: 'Lesson', params: { lessonId: lesson.id, moduleId: moduleId } }).catch(() => {
          return
        })
      }
    }
    if (isMobileDevice.value) emit('show-content')
  }

  const moduleContentCount = computed(() => {
    const contentCount = {
      lessonsCount: 0,
      moduleDuration: 0,
    }
    if (!filteredCourseModules.value) return contentCount
    filteredCourseModules.value.forEach((module) => {
      contentCount.moduleDuration += guidedQuizDuration.value(module.guidedQuiz?.numberOfQuestions || 0)
      module.lessons.forEach((lesson) => {
        contentCount.moduleDuration += lesson.duration || 0
      })
      contentCount.lessonsCount += module.lessons.length
    })
    return contentCount
  })

  const completedProgressCount = computed(() => {
    return (module: CourseModuleState) => {
      let count = 0
      const completedLessonsCount = module.lessons.filter((lesson) => lesson.completed && lesson.type !== 'Quiz').length
      module.lessons.find((lesson) => {
        return lesson.quizMeta?.passed ? count++ : ''
      })
      return `${count + completedLessonsCount}/${module.lessons.filter((l) => l.type !== 'Fake').length}`
    }
  })

  watch(moduleId, (newModuleId) => {
    if (!isModuleOpen.value(newModuleId)) {
      toggleModule(newModuleId)
    }
    setModuleCourseGroup()
  })

  watch(lessonId, (newLessonId: string, oldLessonId: string) => {
    if (!oldLessonId && newLessonId) {
      setModuleCourseGroup()
    }
  })

  const courseAndModulesReady = computed(() => !!(course.value && courseModules.value))

  const showCourseExamDate = computed(
    () =>
      course.value?.exam &&
      (course.value?.trial || course.value?.purchased) &&
      isSachkundeCompany.value &&
      !inEditorMode.value,
  )

  watch(courseAndModulesReady, (value: boolean) => {
    if (value) {
      setModuleCourseGroup()
    }
  })

  watch(selectedCourseGroup, (newCourseGroupId: string, oldCourseGroupId: string) => {
    if (oldCourseGroupId && !route.query.modal) {
      if (!CourseModule.courseModules(currentCourseId.value, newCourseGroupId).length && inEditorMode.value) {
        addNewModule()
      } else {
        if (!moduleId.value) return
        moduleOpenStatus.moduleId = nextEnabledModule.value(currentCourseId.value, newCourseGroupId)?.id || ''
        moduleOpenStatus.open = true
        const lesson = nextLesson(newCourseGroupId)
        if (nextLessonIndex(newCourseGroupId) === 0 || !lesson) {
          router.push({ name: 'CourseModule', params: { moduleId: moduleOpenStatus.moduleId } }).catch(() => {
            return
          })
        } else {
          router
            .push({
              name: 'Lesson',
              params: { moduleId: moduleOpenStatus.moduleId, lessonId: lesson!.id },
            })
            .catch(() => {
              return
            })
        }
      }
    }
    const { query } = route
    if (query.modal) router.replace({ query: { ...query, modal: undefined } })
    CourseModule.setCurrentCourseGroup(newCourseGroupId)
  })

  watch(currentCourseGroupId, (value) => {
    if (value) {
      selectedCourseGroup.value = value
    }
  })

  watch(trialPeriodExpired, (value) => {
    if (value) {
      emit('trial-period-expired', true)
    }
  })

  watch(inEditorMode, (value) => {
    if (!value && !currentModule.value.isFree && !course.value?.purchased && onLessonPage.value) {
      // we route to the currentModule since the lessons in it are locked and the course is not purchased
      routeToModule(currentModule.value as CourseModuleState)
    }
  })

  const setActiveLesson = () => {
    const currentLesson = currentModule.value.lessons.find((lesson) => lesson.id === lessonId.value)
    if (currentLesson) {
      CourseModule.setCurrentLessonTitle(currentLesson.title)
    }
  }

  const setModuleCourseGroup = () => {
    const currentCourseModule = courseModules.value?.find((mod) => mod.id === moduleId.value)
    const courseGroup =
      course.value?.courseGroups?.find((group) => {
        return group.id === currentCourseModule?.courseGroupId
      }) || null
    if (courseGroup?.id) {
      selectedCourseGroup.value = courseGroup.id
    } else if (!selectedCourseGroup.value) {
      selectedCourseGroup.value = course.value?.courseGroups?.[0]?.id || ''
    }
    emit('current-course-group-id', selectedCourseGroup.value)
  }

  const courseHasLimitedTrialTime = computed(() => {
    const courseIdsString = process.env.VUE_APP_COURSE_TRIAL_LIMIT_IDS
    if (!courseIdsString) return false
    const courseIds = courseIdsString.split(',')
    return courseIds.includes(course.value!.id)
  })

  const showCourseTrialTimer = computed(
    () => courseHasLimitedTrialTime.value && course.value?.trial && !course.value?.purchased && !inEditorMode.value,
  )

  const canAdvertiseExtension = ref(true)
  onMounted(() => {
    EditorModule.subscribe({
      type: 'discard',
      key: 'new-lesson-changes',
      callback: discardNewLessonChangesCallback,
    })
    if (inEditorMode.value) {
      if (isNewModule.value) {
        addNewModule()
      } else if (isNewLesson.value) {
        addNewLesson()
      }
    }
    eventBus.$on('question-drag-start', () => {
      isDraggingQuestion.value = true
    })
    eventBus.$on('question-drag-end', () => {
      dropModuleTarget.value = null
      dropLessonTarget.value = ''
      isDraggingQuestion.value = false
    })

    canAdvertiseExtension.value = !CookieHelper.getCookieValue('advertise_extension')
    // Both course and course modules endpoints need to respond almost at the same time so we can set the correct
    // courseGroupId. But sometimes, one of the endpoints is faster and that leads to not setting the correct courseGroupId.
    // The solution is to either set the initial courseGroupId when the endpoints respond at the same time or watch by waiting
    // for both endpoints to be ready.
    if (course.value && courseModules.value) {
      setModuleCourseGroup()
    }
    toggleModule(currentModule.value.id)
    setActiveLesson()
  })

  onBeforeUnmount(() => {
    EditorModule.unsubscribe({ type: 'discard', callback: discardNewLessonChangesCallback })
    EditorModule.unsubscribe({ type: 'discard', callback: lessonOrderingUndoCallback })
    EditorModule.unsubscribe({ type: 'save', callback: lessonOrderingRedoCallback })
  })

  const hoveringIndex = ref(-1)
  const totalDownloadMaterials = computed(() => {
    return course.value?.downloadables?.length || 0
  })

  const hasCertificateUrl = computed(() => !!course.value?.certificateTemplateId)

  const openBrowserExtensionModal = () => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'BrowserExtensionModal',
      modalProps: {
        courseId: currentCourseId.value,
        moduleId: moduleId.value,
        lessonId: lessonId.value,
      },
      modalTitle: translateString('browserExtension.modal.title'),
      cssClass: 'browser-extention-modal-wrapper',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const openSpeakToExpertModal = () => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'SpeakToExpertModal',
      cssClass: 'speak-to-expert-modal',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const formattedGuidedQuizDuration = computed(() => {
    return (module: CourseModuleState) => {
      return String(guidedQuizDuration.value(module.guidedQuiz!.numberOfQuestions)).replace('.', ',')
    }
  })

  const closeExtensionAdvert = () => {
    canAdvertiseExtension.value = false
    CookieHelper.setCookieValue('advertise_extension', 'true')
  }

  const isLessonDropTarget = computed(() => {
    return (lid: string) => {
      return (
        inEditorMode.value &&
        lid === dropLessonTarget.value &&
        (isDraggingQuestion.value || isDraggingLesson.value) &&
        !(isDraggingQuestion.value && dropLessonTarget.value === lessonId.value)
      )
    }
  })

  const isModuleDropTarget = computed(() => {
    return (moduleId: string) => {
      const module = filteredCourseModules.value![dragModuleSourceIndex.value]
      const prevModule = filteredCourseModules.value![dragModuleSourceIndex.value - 1]

      return (
        inEditorMode.value &&
        isDraggingModule.value &&
        dropModuleTarget.value?.id === moduleId &&
        module?.id !== moduleId &&
        prevModule?.id !== moduleId
      )
    }
  })

  const isDraggingModule = computed(() => dragModuleSourceIndex.value >= 0)

  const isDraggingLesson = computed(() => dragLessonSourceIndex.value >= 0)

  const onModuleDragStart = (index: number) => {
    dragModuleSourceIndex.value = index
  }

  const onModuleDragEnter = (module: CourseModuleState) => {
    if (module.id !== dropModuleTarget.value?.id) {
      const editorModule = EditorModule.coursesModuleMap[currentCourseId.value]?.find((m) => m.id === module.id)
      dropModuleTarget.value = editorModule || null
      if (isDraggingLesson.value) {
        const editorModule = EditorModule.coursesModuleMap[currentCourseId.value]?.find((m) => m.id === module.id)
        editorModule?.lessons.push(fakeLessonPayload)
      }
      if (isDraggingLesson.value || isDraggingQuestion.value) toggleModule(module.id)
    }
  }

  const onModuleDragEnd = () => {
    dragModuleSourceIndex.value = -1
    dropModuleTarget.value = null
    const index = EditorModule.coursesModuleMap[currentCourseId.value]?.findIndex((m) => m.id === '123')
    if (index !== undefined) EditorModule.coursesModuleMap[currentCourseId.value].splice(index, 1)
  }

  const onModuleDrop = () => {
    if (
      isDraggingModule.value &&
      !isDraggingLesson.value &&
      dragModuleSourceIndex.value >= 0 &&
      dropModuleTarget.value
    ) {
      const moduleToRemove = filteredCourseModules.value![dragModuleSourceIndex.value]
      // ignore drop if source and target module is the same
      if (moduleToRemove.id === dropModuleTarget.value.id) return

      const courseModules = EditorModule.coursesModuleMap[currentCourseId.value]
      const moduleMapCopy = structuredClone(
        EditorModule.coursesModuleMap[currentCourseId.value]?.filter((m) => m.id !== '123') || [],
      ) as CourseModuleState[]
      const moduleToRemoveIndex = courseModules.findIndex((mod) => mod.id === moduleToRemove.id)
      courseModules.splice(moduleToRemoveIndex, 1)

      const newModuleIndex = courseModules.findIndex((mod) => mod.id === dropModuleTarget.value?.id)
      courseModules.splice(newModuleIndex + 1, 0, moduleToRemove)

      eventBus.$emit('turn-on-loader')
      const apiPayload = courseModules
        .filter((m) => m.id !== '123')
        .map((m, index) => ({ moduleId: m.id, position: index }))
      CourseApi.reorderModules(currentCourseId.value, apiPayload)
        .then(async () => {
          eventBus.$toasted.success(translateString('editor.messages.modulesReorderedSuccessfully'))
          await CourseModule.getCourseModules(currentCourseId.value)
          cacheStateAndsubscribeToEditorEvents(
            'modules-reordering-',
            moduleMapCopy,
            apiPayload,
            moduleOrderingUndoCallback,
            moduleOrderingRedoCallback,
          )
        })
        .catch((e) => {
          if (e.response?.status === 403) {
            eventBus.$toasted.error(translateString('editor.messages.onlyEditorsAllowed'))
          }
        })
        .finally(() => {
          eventBus.$emit('turn-off-loader')
        })
    }
  }

  const moduleOrderingUndoCallback = (data: EditorStatePayload) => {
    eventBus.$emit('turn-on-loader')
    const apiPayload = (data.value as CourseMap).modules.map((m, index) => ({ moduleId: m.id, position: index }))
    CourseApi.reorderModules((data.value as CourseMap).courseId, apiPayload)
      .then(async () => {
        eventBus.$toasted.success(translateString('editor.messages.modulesReorderedSuccessfully'))
        await CourseModule.getCourseModules((data.value as CourseMap).courseId)
        const curModules = structuredClone(courseModules.value || []) as CourseModuleState[]
        EditorModule.setCourseModules({ courseId: (data.value as CourseMap).courseId, modules: curModules })
      })
      .finally(() => {
        eventBus.$emit('turn-off-loader')
      })
  }

  const moduleOrderingRedoCallback = (data: EditorStatePayload) => {
    eventBus.$emit('turn-on-loader')
    CourseApi.reorderModules(
      (data.value as CourseMap).courseId,
      data.apiPayload as { moduleId: string; position: number }[],
    )
      .then(async () => {
        eventBus.$toasted.success(translateString('editor.messages.modulesReorderedSuccessfully'))
        await CourseModule.getCourseModules((data.value as CourseMap).courseId)
        const curModules = structuredClone(courseModules.value || []) as CourseModuleState[]
        EditorModule.setCourseModules({ courseId: (data.value as CourseMap).courseId, modules: curModules })
      })
      .finally(() => {
        eventBus.$emit('turn-off-loader')
      })
  }

  const fakeModulePayload: CourseModuleState = {
    id: '123',
    name: '',
    description: '',
    teaserVideoUrl: '',
    teaserImageUrl: '',
    thumbnailTimestamp: null,
    credits: '',
    authors: [],
    orderingNumber: 0,
    isFree: false,
    benefits: [],
    learnings: null,
    lessons: [],
    courseGroupId: null,
    courseGroupOrderingNumber: 0,
    guidedQuiz: null,
    hasTiles: false,
    enableGuidedQuiz: false,
    disabled: false,
    duration: 0,
    numberOfLessons: 0,
  }

  const fakeLessonPayload: Lesson = {
    id: '123',
    title: '',
    orderingNumber: 0,
    duration: 0,
    type: 'Fake',
    completed: false,
    introText: '',
    outroText: '',
    videoUrl: null,
    videoTranscript: null,
    thumbnailTimestamp: 0,
    content: '',
    profileImageUrl: null,
    quiz: null,
    audioFiles: [],
    isOnePager: false,
    notes: null,
    hasNote: false,
    lessonTypeId: '',
    slideShow: [],
    downloadables: {
      downloadUrl: '',
      downloadTeaserImageUrl: '',
      audioUrl: '',
      audioAuthor: null,
      realDownloadData: '',
      downloadTeaserData: '',
    },
    leuckentext: null,
    is_copied_to_webflow: false,
    pending_webflow_update: false,
  }

  const onLessonDragStart = (module: CourseModuleState, lessonIndex: number) => {
    const editorModule = EditorModule.coursesModuleMap[currentCourseId.value]?.find((m) => m.id === module.id)
    editorModule?.lessons.push(fakeLessonPayload)

    dragModuleSource.value = editorModule as CourseModuleState
    dragLessonSourceIndex.value = lessonIndex
  }

  const onLessonDragEnter = (module: CourseModuleState, lessonId: string) => {
    const editorModule = EditorModule.coursesModuleMap[currentCourseId.value]?.find((m) => m.id === module.id)
    dropModuleTarget.value = editorModule as CourseModuleState
    dropLessonTarget.value = lessonId
    eventBus.$emit('question-drag', editorModule, lessonId)
  }

  const onLessonDragEnd = (module: CourseModuleState) => {
    // param module is the same as dragModuleSource
    let editorModule = EditorModule.coursesModuleMap[currentCourseId.value]?.find((m) => m.id === module.id)
    let fakeIndex = editorModule?.lessons.findIndex((l) => l.type === 'Fake')
    if (typeof fakeIndex === 'number' && fakeIndex >= 0) editorModule?.lessons.splice(fakeIndex, 1)

    editorModule = EditorModule.coursesModuleMap[currentCourseId.value]?.find(
      (m) => m.id === dropModuleTarget.value?.id,
    )
    fakeIndex = editorModule?.lessons.findIndex((l) => l.type === 'Fake')
    if (typeof fakeIndex === 'number' && fakeIndex >= 0) editorModule?.lessons.splice(fakeIndex, 1)

    dropLessonTarget.value = ''
    dragLessonSourceIndex.value = -1
    dropModuleTarget.value = null
    dragModuleSource.value = null
  }

  const onLessonDrop = () => {
    if (isDraggingQuestion.value) {
      eventBus.$emit('lesson-drop')
      return
    }
    if (isDraggingLesson.value && dragLessonSourceIndex.value >= 0 && dropLessonTarget.value) {
      const moduleMapCopy = structuredClone(
        EditorModule.coursesModuleMap[currentCourseId.value] || [],
      ) as CourseModuleState[]
      const newIndex = dropModuleTarget.value?.lessons.findIndex((lesson) => lesson.id === dropLessonTarget.value)
      if (dragLessonSourceIndex.value === newIndex) return

      const removedLesson = dragModuleSource.value?.lessons.splice(dragLessonSourceIndex.value, 1)
      if (typeof newIndex === 'number' && newIndex > -1 && removedLesson) {
        dropModuleTarget.value?.lessons.splice(newIndex, 0, removedLesson[0])
      }

      const apiPayload: { moduleId: string; lessons: { id: string; position: number }[] }[] = []
      let movedFromAnotherModule = false
      const replaceModuleId = dropModuleTarget.value!.id
      if (dragModuleSource.value!.id !== dropModuleTarget.value!.id) {
        movedFromAnotherModule = true
        apiPayload.push({
          moduleId: dragModuleSource.value!.id,
          lessons: dragModuleSource
            .value!.lessons.filter((l) => l.type !== 'Fake')
            .map((lesson, index) => ({ id: lesson.id, position: index })),
        })
      }
      apiPayload.push({
        moduleId: dropModuleTarget.value!.id,
        lessons: dropModuleTarget
          .value!.lessons.filter((l) => l.type !== 'Fake')
          .map((lesson, index) => ({ id: lesson.id, position: index })),
      })

      eventBus.$emit('turn-on-loader')
      CourseApi.reorderLessons(apiPayload)
        .then(async () => {
          eventBus.$toasted.success(translateString('editor.messages.lessonReorderedSuccessfully'))
          await CourseModule.getCourseModules(currentCourseId.value)
          patchCourseModules()
          cacheStateAndsubscribeToEditorEvents(
            'lessons-reordering-',
            moduleMapCopy,
            apiPayload,
            lessonOrderingUndoCallback,
            lessonOrderingRedoCallback,
          )
        })
        .catch((e) => {
          if (e.response?.status === 403) {
            eventBus.$toasted.error(translateString('editor.messages.onlyEditorsAllowed'))
          }
        })
        .finally(() => {
          eventBus.$emit('turn-off-loader')
          if (movedFromAnotherModule && removedLesson?.[0].id === lessonId.value) {
            router.replace({
              name: 'Lesson',
              params: { moduleId: replaceModuleId },
            })
          }
        })
    }
  }

  const patchCourseModules = () => {
    const curModules = structuredClone(courseModules.value || []) as CourseModuleState[]
    CourseModule.setCurrentModule(currentModule.value as CourseModuleState)
    EditorModule.setCourseModules({ courseId: currentCourseId.value, modules: curModules })
    EditorModule.setCurrentModuleLessons(structuredClone(currentModule.value.lessons))
  }

  const cacheStateAndsubscribeToEditorEvents = (
    cacheKey: string,
    moduleMapCopy: CourseModuleState[],
    apiPayload: { moduleId: string; position?: number; lessons?: { id: string; position: number }[] }[],
    discardCallback: (data: EditorStatePayload) => void,
    saveCallback: (data: EditorStatePayload) => void,
  ) => {
    const key = cacheKey + uuidv4()
    EditorModule.addEditorChange({
      key,
      path: 'module-selector',
      skipGrouping: true,
      value: {
        courseId: currentCourseId.value,
        modules: moduleMapCopy,
      },
      apiPayload,
    })
    EditorModule.subscribe({
      type: 'discard',
      key,
      callback: discardCallback,
    })
    EditorModule.subscribe({
      type: 'save',
      key,
      callback: saveCallback,
    })
  }

  const lessonOrderingUndoCallback = (data: EditorStatePayload) => {
    eventBus.$emit('turn-on-loader')
    const apiPayload = (data.apiPayload! as any).map((payload: any) => {
      const module = (data.value as CourseMap).modules.find((m) => m.id === payload.moduleId)
      return {
        moduleId: payload.moduleId,
        lessons:
          module?.lessons
            .filter((l) => l.type !== 'Fake')
            .map((lesson, index) => ({ id: lesson.id, position: index })) || [],
      }
    })
    CourseApi.reorderLessons(apiPayload)
      .then(async () => {
        eventBus.$toasted.success(translateString('editor.messages.lessonReorderedSuccessfully'))
        await CourseModule.getCourseModules((data.value as CourseMap).courseId)
        patchCourseModules()
      })
      .finally(() => {
        eventBus.$emit('turn-off-loader')
      })
  }

  const lessonOrderingRedoCallback = (data: EditorStatePayload) => {
    eventBus.$emit('turn-on-loader')
    CourseApi.reorderLessons(data.apiPayload! as any)
      .then(async () => {
        eventBus.$toasted.success(translateString('editor.messages.lessonReorderedSuccessfully'))
        await CourseModule.getCourseModules((data.value as CourseMap).courseId)
        patchCourseModules()
      })
      .finally(() => {
        eventBus.$emit('turn-off-loader')
      })
  }

  const discardNewLessonChangesCallback = async () => {
    if (isNewLesson.value) {
      await CourseModule.resetCurrentLesson()
      const courseModule = CourseModule.coursesModuleMap[currentCourseId.value]!.find(
        (m) => m.id === moduleId.value,
      ) as CourseModuleState
      const index = courseModule.lessons.findIndex((l) => l.id === lessonId.value)
      courseModule.lessons.splice(index, 1)
      await addNewLesson()
    }
  }

  const addNewLesson = async (modId?: string) => {
    const payload = Object.assign({}, fakeLessonPayload)
    payload.id = isNewLesson.value ? lessonId.value : 'new-' + uuidv4()
    payload.moduleId = modId || moduleId.value
    payload.title = translateString('editor.newLessonTitle')
    payload.type = 'Text'

    const courseModule = CourseModule.coursesModuleMap[currentCourseId.value]!.find(
      (m) => m.id === modId || moduleId.value,
    ) as CourseModuleState
    courseModule.lessons.push(payload)
    CourseModule.resetCurrentLesson()
    CourseModule.setCurrentLesson(payload)
    if (!isNewLesson.value) {
      router.push({ name: 'Lesson', params: { lessonId: payload.id, moduleId: payload.moduleId } }).catch(() => {
        return
      })
    }
  }

  const addNewModule = async () => {
    const payload = Object.assign({}, fakeModulePayload)
    payload.id = isNewModule.value ? moduleId.value : 'mod-' + uuidv4()
    CourseModule.coursesModuleMap[currentCourseId.value]?.push(payload)
    CourseModule.setCurrentModule(currentModule.value as CourseModuleState)
    if (!isNewModule.value) {
      router
        .push({ name: 'CourseModule', params: { courseId: currentCourseId.value, moduleId: payload.id } })
        .catch(() => {
          return
        })
    }
  }

  const showAddCourseGroupModal = () => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'CreateOrUpdateCourseGroupModal',
      modalProps: {
        courseId: currentCourseId.value,
        addingNewCourseGroup: true,
      },
      cssClass: 'create-course-group-modal',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const showEditOrDeleteCourseGroupModal = (
    courseGroupId: string,
    _courseGroupTitle: string,
    deleteCourseGroup: boolean,
  ) => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'EditOrDeleteCourseGroupModal',
      modalProps: {
        courseGroupId,
        _courseGroupTitle,
        deleteCourseGroup,
      },
      cssClass: 'edit-or-delete-course-group-modal',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const editCourseGroup = (payload: { selectable: boolean; type: string; title: string }) => {
    showEditOrDeleteCourseGroupModal(payload.type, payload.title, false)
  }

  const deleteCourseGroup = (payload: { selectable: boolean; type: string; title: string }) => {
    showEditOrDeleteCourseGroupModal(payload.type, payload.title, true)
  }
</script>

<style lang="postcss">
  .selector-container {
    @apply ketch-flex ketch-flex-col;
    .status-cta {
      @apply ketch-w-full ketch-flex ketch-items-center ketch-justify-between;
      @apply ketch-order-2 ketch-mt-c20 md:ketch-mt-0 md:ketch-mb-c15 md:ketch-order-1;
      .status {
        @apply ketch-flex ketch-flex-col;
        .helper-text {
          @apply ketch-text-secondary-text-color ketch-mb-c5;
        }
      }
      .cta {
        @apply ketch-h-c40 ketch-w-c200 ketch-bg-transparent ketch-border ketch-border-border-color;
        @apply ketch-flex ketch-items-center ketch-justify-center ketch-rounded-normal ketch-cursor-pointer;
        h5 {
          @apply ketch-font-bold;
        }
        svg {
          @apply ketch-ml-c20;
        }
        &.buyCourse {
          @apply ketch-border-primary-color ketch-text-primary-foreground-color ketch-bg-primary-color ketch-space-x-c10;
        }
      }
    }
    .course-exam-date {
      @apply md:ketch-order-3 md:ketch-mb-c15;
    }
    .extension-banner {
      @apply ketch-bg-[#FFAF34] ketch-rounded-large ketch-py-c12 ketch-px-c20 ketch-mb-c15;
      @apply ketch-order-4 md:ketch-order-2 ketch-relative ketch-cursor-pointer;
      .close {
        @apply ketch-absolute ketch-right-c10 ketch-top-c10 ketch-cursor-pointer;
      }
      .text .bold {
        @apply ketch-font-bold;
      }
      .extensions {
        @apply ketch-flex ketch-space-x-c30 ketch-mt-c10;
        img {
          @apply ketch-w-c20 ketch-h-c20;
        }
        .chrome,
        .edge {
          @apply ketch-space-x-c8 ketch-flex ketch-items-center;
        }
      }
    }
    .selector-wrapper {
      @apply ketch-order-1 md:ketch-order-3;
      .in-trial-course-timer {
        @apply ketch-mb-c15;
      }
      .selector-content-container {
        @apply ketch-bg-module-selector-background-color ketch-overflow-hidden ketch-z-[1];
        @apply md:ketch-border md:ketch-border-border-color md:ketch-rounded-large;
        > * {
          @apply ketch-text-module-selector-foreground-color;
        }
        &.editing {
          @apply ketch-border-t-0 ketch-rounded-t-none;
        }
      }
      .editor-buttons {
        @apply ketch-rounded-t-large ketch-px-c20 ketch-mb-0 ketch-border ketch-border-border-color;
      }
      .left-attr {
        @apply ketch-w-c25 ketch-flex ketch-items-center ketch-justify-center;
        flex: 0 0 25px;
      }
      .header-section {
        @apply ketch-py-c20 ketch-border-b ketch-border-border-color ketch-space-y-c20;
        @apply ketch--mx-c16 ketch-px-[32px] sm:ketch--mx-c20 sm:ketch-px-c40;
        .header-info {
          @apply ketch-font-bold md:ketch-border-border-color md:ketch-border-b md:ketch-pb-c20;
        }
        .dropdown {
          .selected-item {
            @apply ketch-border-b ketch-rounded-normal ketch-bg-secondary-color;
            @apply ketch-border-secondary-hover-color ketch-text-secondary-foreground-color;
            h5 {
              @apply ketch-text-ellipsis ketch-overflow-hidden ketch-max-w-[252px];
            }
            &.open {
              @apply ketch-border-b-0 ketch-rounded-b-none;
            }
          }
          .dropdown-items {
            @apply ketch-border-l ketch-border-r ketch-overflow-hidden;
            &,
            .item {
              @apply ketch-border-secondary-hover-color;
            }
            .item {
              @apply hover:ketch-bg-secondary-hover-color hover:ketch-text-secondary-foreground-color;
            }
            &.open {
              @apply ketch-rounded-b-normal;
            }
          }
        }
        button.add-to-course-group {
          @apply ketch-w-full;
        }
        .meta-info {
          @apply ketch-flex ketch-items-center ketch-justify-between;
          .content-count,
          .duration {
            @apply ketch-flex ketch-items-center ketch-space-x-c10;
          }
        }
      }
      .modules-lessons {
        .module {
          @apply ketch-border-b ketch-border-border-color ketch-py-c15 ketch-border-l-2 ketch-border-transparent;
          &.active {
            @apply ketch-border-l-2 ketch-border-primary-color;
            border-bottom-color: var(--border-color);
          }
          &.open {
            @apply ketch-bg-primary-color ketch-bg-opacity-[10%];
          }
          &.fake {
            @apply ketch-py-c5 ketch-border-b-0;
          }
          &.placeholder {
            @apply ketch-hidden;
          }
          &.drop-target + .module.placeholder {
            @apply ketch-border-2 ketch-border-dashed ketch-border-editor-primary-color;
            @apply ketch-h-c30 ketch-w-full ketch-block;
          }
          &:last-of-type {
            @apply ketch-border-b-0;
          }
          .overall-info {
            @apply ketch-flex ketch-items-center ketch-space-x-c10;
          }
          .meta-info {
            @apply ketch-flex ketch-items-center ketch-space-x-c10 ketch-text-gray-400;
          }
          &[disabled='disabled'] {
            @apply ketch-opacity-30 ketch-pointer-events-none;
          }
          .guided-quiz-container {
            @apply ketch-relative;
            &.in-editor-mode {
              h5 {
                @apply ketch-text-opacity-10 ketch-text-primary-text-color;
              }
              svg {
                @apply ketch-opacity-10;
              }
              .blurred-out-non-editable-section {
                @apply ketch-bg-transparent;
              }
            }
            &.locked {
              @apply ketch-opacity-50 ketch-pointer-events-none;
            }
          }
          .module-wrapper,
          .lesson {
            @apply ketch-flex ketch-items-center ketch-justify-between;
          }
          .add-new-lesson {
            @apply ketch-flex ketch-items-center ketch-space-x-c10 ketch-h-c30 ketch-px-c20;
            @apply ketch-bg-editor-primary-color ketch-bg-opacity-10 ketch--mx-c20 ketch-cursor-pointer;
            h5 {
              @apply ketch-font-bold ketch-text-editor-primary-color;
            }
            .left-attr {
              @apply ketch-flex ketch-justify-center;
              flex: 0 0 25px;
              svg {
                @apply ketch-w-c20 ketch-h-c20;
              }
            }
          }
          .module-info {
            @apply ketch-flex ketch-flex-col ketch-space-y-c8 ketch-px-c16 md:ketch-px-c20;
            .free {
              @apply ketch-w-c45;
            }
            .module-wrapper {
              @apply ketch-relative;
              .overall-info {
                @apply ketch-cursor-pointer;
                .name {
                  @apply ketch-whitespace-pre-wrap;
                  word-break: break-word;
                  &.active {
                    @apply ketch-font-bold;
                  }
                }
                &:hover {
                  .name {
                    @apply ketch-text-primary-color;
                  }
                }
              }
              .meta-info {
                @apply ketch-cursor-pointer;
                svg {
                  @apply ketch-w-c20 ketch-ml-c20 ketch-transition-transform ketch-duration-300;
                }
                .expand-button {
                  @apply ketch-rotate-0;
                  &.open {
                    @apply ketch-rotate-180;
                  }
                }
              }
              .drag-icon {
                @apply ketch-absolute ketch-left-[-15px];
              }
            }
            .ordering-number {
              @apply ketch-w-[24px] ketch-h-[24px] ketch-border ketch-border-border-color ketch-rounded-full;
              @apply ketch-flex ketch-items-center ketch-justify-center ketch-text-gray-400;
              &.active {
                @apply ketch-bg-gray-700 ketch-border-gray-700 ketch-text-white;
              }
            }
          }
          .lessons {
            @apply ketch-mt-c5 ketch-px-c16 md:ketch-px-c20;
            .lesson {
              @apply ketch-py-c5 ketch-flex ketch-items-center ketch-space-x-c10;
              &.active {
                @apply ketch-bg-primary-color ketch-bg-opacity-30 ketch--mx-c20 ketch-px-c20;
                .overall-info {
                  .lesson-title {
                    @apply ketch-font-bold;
                  }
                }
              }
              &.drop-target {
                &.space-out {
                  @apply ketch-flex-col;
                }
                &.space-out:before,
                &:not(.space-out) {
                  @apply ketch-border-2 ketch-border-dashed ketch-border-editor-primary-color;
                  @apply ketch-min-h-[30px] ketch-w-full ketch-relative;
                  content: '';
                }
              }
              &.locked {
                @apply ketch-opacity-50;
              }
              .wrapper {
                @apply ketch-flex ketch-items-center ketch-justify-between ketch-w-full;
              }
              .overall-info {
                @apply ketch-cursor-pointer ketch-mr-c5;
                .left-attr {
                  img,
                  svg {
                    @apply ketch-h-c16 ketch-w-c16;
                  }
                  img.active-lesson-icon {
                    filter: invert(1);
                  }
                }
                .lesson-title {
                  @apply hover:ketch-text-primary-color ketch-whitespace-pre-wrap;
                  word-break: break-word;
                }
              }
            }
            .guided-quiz-title {
              @apply ketch-flex ketch-items-center ketch-space-x-c10 ketch-py-c5 hover:ketch-text-primary-color;
              &.active {
                @apply ketch-bg-primary-color ketch-bg-opacity-30 ketch--mx-c20 ketch-px-c20;
                h5 {
                  @apply ketch-font-bold;
                }
              }
              .details {
                @apply ketch-flex ketch-justify-between ketch-flex-1;
              }
            }
          }
        }
        .talk-to-expert {
          @apply ketch-flex ketch-items-center ketch-justify-center ketch-cursor-pointer ketch-py-c15;
          .expert-wrapper {
            @apply ketch-flex ketch-items-center ketch-space-x-c10 ketch-p-c5 ketch-rounded-[100px] ketch-bg-primary-color;
            @apply ketch-text-primary-foreground-color ketch-w-[270px];
            .expert {
              @apply ketch-w-c55 ketch-h-c55;
            }
            .helper-texts {
              @apply ketch-flex ketch-flex-col ketch-max-w-[153px];
              h5 {
                @apply ketch-font-bold;
              }
            }
          }
        }
        .download-materials {
          @apply ketch-flex ketch-justify-between ketch-bg-gray-200 ketch-cursor-pointer;
          @apply ketch-px-c20 hover:ketch-bg-secondary-hover-color;
          .icon-copy {
            @apply ketch-flex ketch-items-center ketch-space-x-c10 ketch-flex-1;
            svg {
              flex: 0 0 25px;
            }
          }
          .total-materials,
          h5 {
            @apply ketch-font-bold ketch-text-primary-text-color;
          }
          &:hover h5,
          &:hover .total-materials {
            @apply ketch-text-secondary-foreground-color;
          }
          &:hover svg .icon-fill {
            fill: var(--secondary-foreground-color) !important;
          }
        }
      }
      .add-module {
        @apply ketch-px-c20 ketch-py-c15 ketch-flex ketch-items-center ketch-space-x-c10;
        @apply ketch-bg-editor-primary-color ketch-bg-opacity-10 ketch-cursor-pointer;
        h5 {
          @apply ketch-font-bold ketch-text-editor-primary-color;
        }
      }
      .webinar-image {
        @apply ketch-cursor-pointer;
        img {
          @apply ketch-w-full;
        }
      }
    }
    .start-exam-cta {
      @apply ketch-order-3 ketch-mt-c15;
    }
    .passed-quiz-status {
      @apply ketch-flex ketch-items-center ketch-justify-between ketch-mt-c15 ketch-order-4;
      @apply ketch-relative md:ketch-order-4;
      .passed-quiz {
        @apply ketch-max-w-[155px];
      }
      .cert-cta {
        @apply ketch-flex ketch-items-center ketch-justify-center ketch-space-x-c10 ketch-px-c20 ketch-bg-secondary-color;
        @apply ketch-text-secondary-foreground-color ketch-rounded-normal ketch-w-full ketch-h-[48px] ketch-max-w-[188px];
        &.passed-all-quizzes {
          @apply ketch-cursor-pointer;
        }
        &:not(.passed-all-quizzes) {
          @apply ketch-opacity-[0.4];
        }
        img {
          filter: invert(1);
        }
      }
    }
    .google-review-widget-wrapper {
      @apply ketch-overflow-hidden ketch-order-last ketch-mt-c15;
    }
  }
  .ketch-dark .selector-container {
    .status-cta {
      .cta {
        img {
          filter: none;
        }
        &.buyCourse {
          img {
            filter: invert(1);
          }
        }
      }
    }
    .selector-wrapper {
      .modules-lessons {
        .module {
          @apply ketch-border-gray-440;
          .module-info {
            .ordering-number {
              &.active {
                @apply ketch-bg-white ketch-border-white ketch-text-gray-600;
              }
            }
          }
          .lessons {
            .lesson {
              .overall-info {
                .left-attr {
                  img.active-lesson-icon {
                    filter: none;
                  }
                }
              }
            }
          }
          .module-gradient {
            background: linear-gradient(
              180deg,
              rgba(17, 17, 34, 0.1) 0%,
              rgba(17, 17, 34, 0.4) 15%,
              rgba(17, 17, 34, 0.7) 38%,
              rgba(17, 17, 34, 1) 100%
            );
            .lock {
              @apply ketch-bg-gray-600;
            }
          }
        }
        .download-materials {
          .total-materials,
          .icon-copy h5 {
            @apply ketch-text-primary-foreground-color;
          }
          &:hover h5,
          &:hover .total-materials {
            @apply ketch-text-secondary-foreground-color;
          }
        }
      }
    }
    .passed-quiz-status {
      .cert-cta {
        img {
          filter: none;
        }
      }
    }
  }
</style>
